.sitemap__block {
  margin-bottom: 0;
}
.sitemap__block a {
  padding-left: 15px;
  text-decoration: none;
  color: #333;
  white-space: nowrap;
}
.sitemap__block li.layer1 {
  width: 100%;
  margin-bottom: 50px;
}
.sitemap__block li.layer1 h3 {
  border-bottom: 1px solid #c0dbec;
  height: 4rem;
}
.sitemap__block li.layer1 ul {
  margin-right: 0;
}
.sitemap__block li.layer2 {
  width: 100%;
}
.sitemap__block .no-arrow {
  padding-left: 15px;
}
.sitemap__block .e_btn_arw::after {
  display: inline-block;
  right: unset;
  margin-left: 15px;
}
.sitemap__block .e_btn_arw:hover::after {
  display: inline-block;
  right: unset;
  margin-left: 20px;
}

@media screen and (min-width: 992px) {
  .sitemap__block {
    display: flex;
    flex-wrap: wrap;
  }
  .sitemap__block li.layer1 ul {
    margin-right: 0;
  }
  .sitemap__cont {
    width: 50%;
  }
  .sitemap__cont:first-child {
    width: 100%;
    padding-right: 50%;
  }
  .sitemap__cont:last-child > ul {
    margin: 0 0 0 35px;
  }
  .sitemap__cont ul {
    width: 100%;
  }
}