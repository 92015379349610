.sitemap {
  &__block {
    margin-bottom: 0;

    a {
      padding-left: 15px;
      text-decoration: none;
      color: #333;
      white-space: nowrap;
    }

    li.layer1 {
      width: 100%;
      margin-bottom: 50px;

      h3 {
        border-bottom: 1px solid #c0dbec;
        height: 4rem;
      }

      ul {
        margin-right: 0;
      }
    }

    li.layer2 {
      width: 100%;
    }

    .no-arrow {
      padding-left: 15px;
    }

    .e_btn_arw::after {
      display: inline-block;
      right: unset;
      margin-left: 15px;
    }

    .e_btn_arw:hover::after {
      display: inline-block;
      right: unset;
      margin-left: 20px;
    }
  }
}

@media screen and (min-width: 992px) {
  .sitemap {
    &__block {
      display: flex;
      flex-wrap: wrap;

      li.layer1 ul {
        margin-right: 0;
      }
    }
    &__cont {
      width: 50%;
      &:first-child {
        width: 100%;
        padding-right: 50%;
      }
      &:last-child > ul {
        margin: 0 0 0 35px;
      }
      ul {
        width: 100%;
      }
    }
  }
}
